import ab1 from './ab1.jpg';
import './app.css';

function App() {
  return (
    <div className="app">
      <img src={ab1} alt='person'/>
    </div>
  );
}

export default App;

